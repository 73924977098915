::-webkit-scrollbar {
  width: 0.6rem;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 0.5rem;
  background: linear-gradient(310deg, #0075ff, #21d4fd);
}

.flow-editor__sidebar::-webkit-scrollbar-thumb {
  background: transparent;
}

.flow-editor__sidebar:hover::-webkit-scrollbar-thumb {
  background: linear-gradient(310deg, #0075ff, #21d4fd);
}

.MuiPaper-root {
  background-color: transparent !important;
}

.react-flow__node input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}